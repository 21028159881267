import { MDXProviderComponentsProp } from '@mdx-js/react';
import {
  Blockquote,
  H1,
  H2,
  H3,
  H4,
  H5,
  H6,
  InlineCode,
  ListItem,
  OrderedList,
  Paragraph,
  TextLink,
  UnorderedList,
} from '../design';
import { Code } from '../code';

export const components: MDXProviderComponentsProp = {
  p: Paragraph,
  a: TextLink,
  code: Code,
  inlineCode: InlineCode,
  h1: H1,
  h2: H2,
  h3: H3,
  h4: H4,
  h5: H5,
  h6: H6,
  ul: UnorderedList,
  ol: OrderedList,
  li: ListItem,
  blockquote: Blockquote,
};
