import React, { ReactElement } from 'react';
import rss from '@iconify/icons-fa-solid/rss-square';
import linkedIn from '@iconify/icons-fa-brands/linkedin';
import twitter from '@iconify/icons-fa-brands/twitter-square';
import email from '@iconify/icons-ant-design/mail-outline';
import { FooterNavLink } from './footer-nav-link';

export const PageFooter = (): ReactElement => (
  <footer className="text-sm font-sans w-full bg-main text-white">
    <div className="max-w-screen-lg mx-auto px-8 py-16">
      <div className="flex justify-between items-center">
        <span>© Piotr Staniów's Blog {new Date().getFullYear()}</span>
        <ul className="flex gap-4 sm:gap-2">
          <li>
            <FooterNavLink to="https://twitter.com/PiotrStaniow" icon={twitter} external />
          </li>
          <li>
            <FooterNavLink
              to="https://www.linkedin.com/in/piotr-staniow/"
              icon={linkedIn}
              external
            />
          </li>
          <li>
            <FooterNavLink to="/rss.xml" icon={rss} external={false} />
          </li>
          <li>
            <FooterNavLink to="mailto:blog@piotrstaniow.pl" icon={email} external />
          </li>
        </ul>
      </div>
      <p className="py-4 text-xs italic font-serif">
        Opinions are my own and do not represent the views of my current or past employers.
      </p>
    </div>
  </footer>
);
