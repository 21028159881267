import { useCallback, useEffect, useState } from 'react';

export const useIsAtTop = (threshold = 0) => {
  const [isAtTop, setIsAtTop] = useState(true);
  const evtListener = useCallback(() => {
    setIsAtTop(window.pageYOffset <= threshold);
  }, []);
  useEffect(() => {
    window.addEventListener('scroll', evtListener);
    return () => window.removeEventListener('scroll', evtListener);
  });
  return isAtTop;
};
