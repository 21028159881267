import React from 'react';
import classNames from 'classnames';

export enum AlertVariant {
  ERROR = 'error',
  INFO = 'info',
}

interface AlertProps {
  variant: AlertVariant;
  title: string;
  description?: string;
}

interface AlertTheme {
  background: string;
  border: string;
  color: string;
  pillBackground: string;
}

function getTheme(variant: AlertVariant): AlertTheme {
  switch (variant) {
    case AlertVariant.ERROR:
      return {
        background: 'bg-red-100',
        border: 'border-red-500',
        color: 'text-red-900',
        pillBackground: 'bg-red-500',
      };
    case AlertVariant.INFO:
      return {
        background: 'bg-teal-100',
        border: 'border-teal-500',
        color: 'text-teal-900',
        pillBackground: 'bg-teal-500',
      };
  }
}

export const Alert: React.FC<AlertProps> = ({ variant, title, description, children }) => {
  const { border, background, pillBackground, color } = getTheme(variant);
  return (
    <div
      className={classNames('border-t-4 px-4 py-3 shadow-md mb-4', background, border, color)}
      role="alert"
    >
      <div className="flex">
        <div className="py-1">
          <span
            className={classNames(
              'flex rounded-full uppercase px-2 py-1 text-xs font-bold mr-3',
              pillBackground,
            )}
          >
            {variant.toUpperCase()}
          </span>
        </div>
        <div>
          <span className="font-bold">{title}</span>
          {description && <span className="text-sm">{description}</span>}
          {children && <span className="text-sm">{children}</span>}
        </div>
      </div>
    </div>
  );
};
