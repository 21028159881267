import React, { ReactElement } from 'react';
import { Alert, AlertVariant } from '../alert';

enum ScopeState {
  OK,
  ERROR,
}

interface ErrorBoundaryState {
  scopeState: ScopeState;
}

export interface ErrorBoundaryProps {
  scope: string;
  title?: string;
  description?: string;
}

export class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { scopeState: ScopeState.OK };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    console.error(errorInfo);
    console.error(error);
    this.setState({ scopeState: ScopeState.ERROR });
  }

  render(): ReactElement {
    const { scopeState } = this.state;
    const { title = 'Unexpected error occurred', description, scope, children } = this.props;
    if (scopeState === ScopeState.ERROR) {
      console.error(`Error occurred in scope: ${scope}`);
      return <Alert title={title} description={description} variant={AlertVariant.ERROR} />;
    }
    return <>{children}</>;
  }
}
