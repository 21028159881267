import React from 'react';
import classNames from 'classnames';

interface HeadingBaseProps {
  component: React.ElementType;
  className?: string;
  withMargins?: boolean;
  withColor?: boolean;
  fontFamily?: 'body' | 'sans' | 'serif';
}

type HeadingProps = Omit<HeadingBaseProps, 'component'>;

export const HeadingBase: React.FC<HeadingBaseProps> = ({
  component: Component,
  className,
  withMargins = true,
  withColor = true,
  fontFamily = 'sans',
  ...props
}) => (
  <Component
    className={classNames(
      `font-${fontFamily}`,
      {
        'mt-11': withMargins,
        'mb-5': withMargins,
        'text-main': withColor,
      },
      className,
    )}
    {...props}
  />
);

export const H0: React.FC<HeadingProps> = ({ className, ...props }) => (
  <HeadingBase {...props} className={classNames('text-huge', className)} component="h1" />
);

export const H1: React.FC<HeadingProps> = ({ className, ...props }) => (
  <HeadingBase {...props} className={classNames('text-h1', className)} component="h1" />
);

export const H2: React.FC<HeadingProps> = ({ className, ...props }) => (
  <HeadingBase {...props} className={classNames('text-h2', className)} component="h2" />
);

export const H3: React.FC<HeadingProps> = ({ className, ...props }) => (
  <HeadingBase {...props} className={classNames('text-h3', className)} component="h3" />
);

export const H4: React.FC<HeadingProps> = ({ className, ...props }) => (
  <HeadingBase {...props} className={classNames('text-h4', className)} component="h4" />
);

export const H5: React.FC<HeadingProps> = ({ className, ...props }) => (
  <HeadingBase {...props} className={classNames('text-h5', className)} component="h5" />
);

export const H6: React.FC<HeadingProps> = ({ className, ...props }) => (
  <HeadingBase {...props} className={classNames('text-h6', className)} component="h6" />
);
