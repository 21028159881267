import { graphql, useStaticQuery } from 'gatsby';
import { RawBlogPostModel, BlogPostModel } from '../models';

interface LatestPostsQueryResult {
  latest: {
    edges: RawBlogPostModel[];
  };
}

export const useLatestPosts = (): BlogPostModel[] => {
  const {
    latest: { edges },
  } = useStaticQuery<LatestPostsQueryResult>(graphql`
    query {
      latest: allMdx(
        limit: 5
        sort: { fields: [frontmatter___date], order: DESC }
        filter: { frontmatter: { hidden: { ne: true } } }
      ) {
        edges {
          node {
            id
            fields {
              slug
            }
            frontmatter {
              title
              date(formatString: "DD MMM YYYY")
              description
              tags
              category
            }
            timeToRead
          }
        }
      }
    }
  `);
  return edges.map(
    ({
      node: {
        id,
        fields: { slug },
        frontmatter: { title, date, description, tags, category },
        timeToRead,
      },
    }) => ({
      id,
      slug,
      title,
      date,
      description,
      tags,
      category,
      timeToRead,
    }),
  );
};
