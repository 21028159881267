import React, { ComponentProps, memo, ReactElement } from 'react';
import { Icon } from '@iconify/react';
import { Link } from 'gatsby';

interface FooterNavLinkProps {
  to: string;
  icon: ComponentProps<typeof Icon>['icon'];
  className?: string;
  external: boolean;
}

export const FooterNavLink = memo(
  ({ to, icon, external, className = '' }: FooterNavLinkProps): ReactElement => {
    const iconBody = <Icon icon={icon} className="w-6 h-6" />;
    const classes = `text-white hover:text-cta hover:underline ${className}`;
    if (external) {
      return (
        <a href={to} className={classes} target="_blank" rel="noopener noreferrer">
          {iconBody}
        </a>
      );
    }
    return (
      <Link to={to} className={classes}>
        {iconBody}
      </Link>
    );
  },
);
