import React, { ReactElement } from 'react';
import classNames from 'classnames';

type Anchor = 'a';

export enum TextLinkVariant {
  MAIN = 'main',
  ACCENT = 'accent',
  CTA = 'cta',
}

interface SharedProps {
  variant?: TextLinkVariant;
}

type AnchorProps = JSX.IntrinsicElements['a'] & SharedProps & { as?: Anchor };
type GenericProps<Props extends object> = SharedProps & { as: React.ComponentType<Props> };

const MAIN_STYLE =
  'text-main-lighter hover:text-main visited:text-main-darker active:text-main-dark';
const ACCENT_STYLE =
  'text-accent hover:text-accent-lighter visited:text-accent-darker active:text-accent-dark';
const CTA_STYLE = 'text-cta hover:text-main-lighter visited:text-main-darker active:text-main-dark';

const defaultComponent = 'a' as const;

export function TextLink<Props extends object>(props: Props & GenericProps<Props>): ReactElement;
export function TextLink(props: AnchorProps): ReactElement;
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function TextLink({
  as: Component = defaultComponent,
  variant = TextLinkVariant.MAIN,
  ...props
}: any): ReactElement {
  const className = classNames('hover:underline', 'cursor-pointer', {
    [MAIN_STYLE]: variant === TextLinkVariant.MAIN,
    [ACCENT_STYLE]: variant === TextLinkVariant.ACCENT,
    [CTA_STYLE]: variant === TextLinkVariant.CTA,
  });
  return <Component {...props} className={className} />;
}
