import React from 'react';
import Helmet from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

interface SEOProps {
  description?: string;
  lang?: string;
  meta?: Array<JSX.IntrinsicElements['meta']>;
  title: string;
}

const SEO: React.FC<SEOProps> = React.memo(
  ({ description = '', lang = 'en', meta = [], title }) => {
    const { site } = useStaticQuery(
      graphql`
        query {
          site {
            siteMetadata {
              title
              description
              author
            }
          }
        }
      `,
    );

    const metaDescription = description || site.siteMetadata.description;
    const metadata: Array<JSX.IntrinsicElements['meta']> = [
      {
        name: 'description',
        content: metaDescription,
      },
      {
        property: 'og:title',
        content: title,
      },
      {
        property: 'og:description',
        content: metaDescription,
      },
      {
        property: 'og:type',
        content: 'website',
      },
      {
        name: 'twitter:card',
        content: 'summary_large_image',
      },
      {
        name: 'twitter:site',
        content: '@PiotrStaniow',
      },
      {
        name: 'twitter:creator',
        content: site.siteMetadata.author,
      },
      {
        name: 'twitter:title',
        content: title,
      },
      {
        name: 'twitter:description',
        content: metaDescription,
      },
    ];

    return (
      <Helmet
        htmlAttributes={{
          lang,
        }}
        title={title}
        titleTemplate={`%s | ${site.siteMetadata.title}`}
        meta={metadata.concat(meta)}
      />
    );
  },
);
SEO.displayName = 'SEO';
export default SEO;
