import React from 'react';
import classNames from 'classnames';

type NativeParagraphProps = JSX.IntrinsicElements['p'];

interface ParagraphProps extends NativeParagraphProps {
  className?: string;
  justify?: boolean;
  fontFamily?: 'serif' | 'body' | 'sans';
  color?: string;
}

export const Paragraph: React.FC<ParagraphProps> = ({
  className,
  justify = true,
  fontFamily = 'body',
  color = 'text-gray-800',
  ...props
}) => (
  <p
    {...props}
    className={classNames(
      'mb-4 leading-relaxed',
      `font-${fontFamily}`,
      color,
      { 'text-justify': justify },
      className,
    )}
    style={{ hyphens: 'auto' }}
  />
);
