import React, { ReactElement, useCallback, useState } from 'react';
import NavLink from './navlink';
import classNames from 'classnames';
import { Link } from 'gatsby';
import { useIsAtTop } from '../../hooks';
import { HeaderContainer, MenuDropdown } from './header.module.css';

interface HeaderProps {
  flatWhite?: boolean;
}

const Header = ({ flatWhite = false }: HeaderProps): ReactElement => {
  const [expanded, setExpanded] = useState(false);
  const toggleExpanded = useCallback(() => setExpanded(!expanded), [expanded, setExpanded]);
  const isAtTop = useIsAtTop(32);
  return (
    <header
      className={classNames(
        'sticky top-0 w-full z-50',
        {
          'bg-main text-white': !flatWhite && isAtTop,
          'bg-main-lightest text-main backdrop-filter backdrop-blur-md backdrop-opacity-75':
            flatWhite || !isAtTop,
          'shadow-lg': !isAtTop,
        },
        HeaderContainer,
      )}
    >
      <nav className="max-w-screen-lg mx-auto px-4 lg:px-6 flex flex-col lg:flex-row">
        <div className="w-full h-16 flex items-center justify-between flex-grow">
          <Link to="/" className="font-sans font-normal text-lg mt-0 align-middle">
            Piotr Staniów
          </Link>
          <div className="block lg:hidden">
            <button
              className={classNames('px-4 -mr-4 py-2 text-white hover:border-white', {
                'text-main': !flatWhite && !isAtTop,
              })}
              onClick={toggleExpanded}
            >
              <svg
                className="fill-current h-4 w-4"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <title>Menu</title>
                <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
              </svg>
            </button>
          </div>
        </div>
        <div
          className={classNames(
            { 'hidden lg:flex': !expanded },
            'w-full',
            'lg:w-auto',
            'flex',
            'flex-col',
            'lg:flex-row',
            'lg:items-center',
            'text-sm',
            'border-t',
            'border-main',
            'lg:border-t-0',
            '-space-y-4',
            'lg:space-y-0',
            MenuDropdown,
          )}
        >
          <NavLink to="/blog">Blog</NavLink>
          <NavLink to="/resume">Resume</NavLink>
        </div>
      </nav>
    </header>
  );
};

export default Header;
